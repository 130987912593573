import { format, parseISO } from 'date-fns';
import numeral from 'numeral';
import Web3 from 'web3';
import axios from 'axios';
import { AbiItem } from 'web3-utils';
import { v4 as uuidv4 } from 'uuid';
import { KeyboardEvent } from 'react';
import {
  AudienceEvent,
  AudienceOption,
  BuildFunnelData,
  CampaignEvent,
  CampaignReward,
  ChainId,
  ChartData,
  ConfiguredEvent,
  Contract,
  ContractGetSourceCode,
  ContractType,
  ConversionConfig,
  CreativeFormData,
  DimensionOption,
  DimensionType,
  EventLogName,
  EventOption,
  FormattedChartBuilderEvent,
  FunnelDataItem,
  PayoutType,
  PublisherOption,
  TableColumn,
  TableData,
  TargetingOption,
  ValueFormatter,
} from '../types';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { isTableData } from '../components/tables/Table';

const web3 = new Web3(null);

// ------------------ FORMATTING ------------------ //

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type AudienceEventRequest = Pick<AudienceEvent, 'eventName' | 'eventType' | 'unionType' | 'filters' | 'label' | 'relativeDateRange'>;

export function formatChartBuilderEvents(events: ConfiguredEvent[]): FormattedChartBuilderEvent[] {
  return events
    .filter((event) => event.eventType && event.eventName)
    .map((event) => {
      const chartBuilderEvent: FormattedChartBuilderEvent = {
        eventType: event.eventType,
        eventName: event.eventName,
        eventLabel: event.label,
        measure: {
          id: event.measure.id,
          aggType: event.measure.aggType,
          label: event.measure.label,
        },
      };

      if (event.filters && event.filters.length > 0) {
        const selectedFilters = event.filters.filter((filter) => filter.value.length > 0 || filter.type === DimensionType.AUDIENCE);
        if (selectedFilters.length > 0) {
          chartBuilderEvent.filters = selectedFilters.map((filter) => ({
            id: filter.id,
            value: filter.value,
            type: filter.type,
            op: filter.op,
          }));
        }
      }

      if (event.groupBy) {
        chartBuilderEvent.groupBy = {
          id: event.groupBy.id,
          type: event.groupBy.type,
        };
      }

      return chartBuilderEvent;
    });
}

export function formatAudienceEvents(events: AudienceEvent[]): AudienceEventRequest[] {
  const selectedEvents = events.filter((event) => event.eventType);
  return selectedEvents.map((event) => {
    const audienceEvent: AudienceEventRequest = {
      label: event.label,
      eventType: event.eventType,
      eventName: event.eventName,
      unionType: event.unionType,
      relativeDateRange: event.relativeDateRange,
    };

    if (event.filters) {
      const selectedFilters = event.filters.filter((filter) => filter.value.length > 0 || filter.type === DimensionType.AUDIENCE);
      audienceEvent.filters = selectedFilters.map((filter) => ({
        id: filter.id,
        value: filter.value,
        type: filter.type,
        op: filter.op,
      }));
    }

    return audienceEvent;
  });
}

export function formatCampaignEvents(events: CampaignEvent[]) {
  return events.map((event) => {
    const campaignEvent = {
      ...event,
      rewardType: event.rewardType.value,
    };

    return campaignEvent;
  });
}

export function formatTargetingConfig(targetingConfig?: { publishers: PublisherOption[]; segments: TargetingOption[]; audiences: AudienceOption[] }) {
  if (!targetingConfig) return;

  const formattedConfig = [];

  if (targetingConfig.publishers.length > 0) {
    const publishers = targetingConfig.publishers.map((publisher) => publisher.id);
    formattedConfig.push({ key: 'publisher', op: 'in', values: publishers });
  }

  if (targetingConfig.audiences.length > 0) {
    const audiences = targetingConfig.audiences.map((audience) => audience.id);
    formattedConfig.push({ key: 'audience', op: 'in', values: audiences });
  }

  if (targetingConfig.segments.length > 0) {
    const segments = targetingConfig.segments.map((segment) => segment.id);
    formattedConfig.push({ key: 'segment', op: 'in', values: segments });
  }

  return formattedConfig;
}

export const formatCreativeUnit = (data: CreativeFormData) => {
  const { category, image, creativeType, ...rest } = data;

  const formData = new FormData();

  // Add image to FormData
  formData.append('creativeTypeId', creativeType.id);
  if (image) {
    formData.append('image', image.data as File);
    formData.append('imageName', image.name);
  }
  formData.append('category', category.value);

  // Add all other fields to FormData
  Object.entries(rest).forEach(([key, value]) => {
    formData.append(key, value as string);
  });

  return formData;
};

/**
 * @param  {string} value
 * @returns string
 * @description Format a number to a short string
 * @example 1000000 => 1M
 */
export function compactNumberFormatter(value: string | number): string {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(Number(value));
}

export function percentageFormatter(value: string): string {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 1,
  }).format(Number(value));
}

/**
 * @param  {string} value
 * @returns string
 * @description Format a number to sting with no decimals and commas
 */
export const basicNumberFormatter = (value: string | number) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(Number(value));
};

export const numberFormatter = (value: string | number, format: string | undefined): string => {
  try {
    if (!format || !value) return value?.toString() || '';
    if (format === 'false') {
      return value?.toString();
    }

    numeral.options.scalePercentBy100 = false;

    let number = numeral(value);

    let formattedNumber = number.format(format);

    return formattedNumber;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return '';
  }
};

/**
 * @param  {string} date
 * @returns string
 * @description Format a string date to a short string date
 * @example 2021-01-01 => Jan 1, 2021
 */
export function timeSeriesFormatter(date: string, period?: string): string {
  if (period === 'hour') {
    return new Date(date).toLocaleDateString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });
  }

  if (period === 'month') {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC',
    });
  }

  return new Date(date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  });
}

/**
 * @param  {string} str
 * @returns string
 * @description Convert a string to all lowercase
 */
export function lowerCase(str: string): string {
  return str.toLowerCase();
}

/**
 * @param  {string} str
 * @returns string
 * @description Capitalize the first letter of a string
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// To be used in the future
export const defaultValueFormatter: ValueFormatter = (value: number) => value.toString();

/**
 * @param  {string} address
 * @param  {} chars=4
 * @returns string
 * @description Function that takes in address and returns a shortened version of it
 */
export function shortenAddress(address: string, chars = 4): string {
  if (address.length < 42) return address;

  const parsed = Web3.utils.toChecksumAddress(address);
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

/**
 * @param  {string} hash
 * @param  {number} chars=6
 * @returns string
 * @description Function that takes in a hash and returns a shortened version of it
 */
export function shortenHash(hash: string, chars = 6): string {
  if (hash.length <= chars * 2) return hash;

  return `${hash.substring(0, chars)}...${hash.substring(hash.length - chars)}`;
}

export function shortenText(text?: string, chars = 45): string {
  if (!text) return '';
  if (text.length > chars) {
    return text.substring(0, chars - 3) + '...';
  } else {
    return text;
  }
}

/**
 *
 * @param displayName string
 * @returns string
 * @description Function that takes in a display name and returns a url safe slug
 * @example createUrlSafeSlug("This is an Example Name!") => "this-is-an-example-name"
 */
export function createUrlSafeSlug(displayName: string): string {
  return displayName
    .toLowerCase() // Convert the string to lowercase
    .trim() // Remove whitespace from both ends
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters (excluding hyphens)
    .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
    .replace(/^-+/, '') // Remove starting hyphens
    .replace(/-+$/, ''); // Remove trailing hyphens
}

/**
 * @param  {string} date
 * @returns string
 * @description Convert UTC date to local date in 12 hour format
 */

export const convertUTCDateToLocalDate = (date?: string | Date | null, includeSeconds = false) => {
  if (!date) return '';
  const dateObj = typeof date === 'string' ? parseISO(date) : date;
  return format(dateObj, `MM/dd/yyyy hh:mm${includeSeconds ? ':ss' : ''} aa`);
};

export const convertISOToDate = (date: string) => {
  return format(new Date(date), 'dd MMM yyyy');
};

/**
 * @param  {string} date
 * @returns boolean
 * @description Check if a string is a date
 */
export const isDate = (item: any) => {
  if (item === null || item === undefined) {
    return false;
  }

  if (typeof item !== 'string') {
    return false;
  }

  if (!item.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
    return false;
  }

  return !isNaN(Date.parse(item));
};

export function camelCaseMethodToReadable(methodName: string): string {
  if (!methodName) {
    return '';
  }

  const result: string[] = [];
  let currentWord: string[] = [methodName[0].toUpperCase()];

  for (let i = 1; i < methodName.length; i++) {
    const char = methodName[i];

    if (char === char.toUpperCase()) {
      result.push(currentWord.join(''));
      currentWord = [char.toUpperCase()];
    } else {
      currentWord.push(char.toLowerCase());
    }
  }

  if (currentWord.length > 0) {
    result.push(currentWord.join(''));
  }

  return result.join(' ');
}

// ------------------ EVENT CREATION ------------------ //

export async function getContractSourceCode(address: string, blockchain: string): Promise<ContractGetSourceCode | null> {
  if (address.length != 42 || !address.startsWith('0x')) {
    return null;
  }

  const url =
    blockchain === 'ethereum'
      ? `https://api.etherscan.io/api?module=contract&action=getsourcecode&address=${address}&apikey=UEXWQF7PSTESNHI4WB8PIDEYZF66UNEIXA`
      : `https://api.polygonscan.com/api?module=contract&action=getsourcecode&address=${address}&apikey=NWF7VEJQRY76FSMP9ANYZAC4BRSIXQS6VE`;

  try {
    const j = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const r = (await j.json()) as ContractGetSourceCode;

    return r;
  } catch (e) {
    // TODO: Handle error
    // console.log('Error', e);
    return null;
  }
}

export async function getContractABI(response: ContractGetSourceCode, blockchain: string): Promise<string> {
  const result = response.result ? response.result[0] : null;

  if (!result) {
    return '';
  }

  if (result?.Proxy == '1') {
    const implementation = await getContractSourceCode(result.Implementation, blockchain);
    if (implementation) {
      return getContractABI(implementation, blockchain);
    }
  }

  if (result?.ABI === 'Contract source code not verified') {
    return '';
  }

  return result!.ABI;
}

export async function getContractTypes(address: string, blockchain: string): Promise<ContractType[]> {
  let types: Array<string> = [];

  let url = '';
  switch (blockchain) {
    case 'ethereum':
      url = `https://ethereum.rest.mnemonichq.com/contracts/v1beta1/by_address/${address}`;
      break;
    case 'polygon':
      url = `https://polygon.rest.mnemonichq.com/polygon/contracts/v1beta1/by_address/${address}`;
      break;
    default:
      return [];
  }

  try {
    const j = await fetch(url, {
      method: 'GET',
      headers: {
        'X-API-Key': 'EnjFDY1YivSPUSko1YxSH31McsbSOkXwcXvp7iUHNyYdbZlv',
      },
    });
    const r = await j.json();
    types += r?.contract?.types;
  } catch (e) {
    // TODO: Handle error
    // console.log('Error', e);
  }

  if (types.includes('TOKEN_TYPE_ERC20')) {
    return [ContractType.ERC20];
  }

  if (types.includes('TOKEN_TYPE_ERC721')) {
    return [ContractType.ERC721];
  }

  if (types.includes('TOKEN_TYPE_ERC1155')) {
    return [ContractType.ERC1155];
  }

  return [];
}

export function getConversionConfig(abi: string): any {
  const parsedABI = JSON.parse(abi) as AbiItem[];
  try {
    const options = parsedABI
      .filter((b) => b.type === 'function' && b.stateMutability !== 'view' && b.stateMutability !== 'pure')
      .map((b) => {
        const sig = web3.eth.abi.encodeFunctionSignature(b);
        return { value: sig, label: b.name || '', enabled: false };
      });
    return options;
  } catch (e) {
    // TODO: Handle error
    // console.log('Error', e);
    return [];
  }
}

export const deleteContract = async (contract: Contract, organization: any, accessToken: string) => {
  let deleteUrl = `${UrlConfig.serverHost}/${organization?.publicMetadata.spindl_org_id}/contracts/${contract.id}`;

  const response = await fetch(deleteUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.status;
};

export async function updateContract(name: string, organization: any, conversionConfig: ConversionConfig[], contractID: string, accessToken: string) {
  let updateUrl = `${UrlConfig.serverHost}/${organization?.publicMetadata.spindl_org_id}/contracts/${contractID}`;

  const fields = {
    name: name,
    conversionConfig: conversionConfig,
  };

  const response = await fetch(updateUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  });

  return response.status;
}

// ------------------ MISCELLANEOUS ------------------ //

// both CLIENT_HOST and SERVER_HOST are set in the next.config.js file as environment variables.
// This is done so that we can access the same environment variables in both the client and server
// without having to worry about NEXT_PUBLIC_ prefix
export const UrlConfig = {
  clientHost: process.env.CLIENT_HOST,
  // Covers scenario where we are running the server locally or want to use prod server
  serverHost: process.env.SERVER_HOST,
} as const;

// for taking contract data and converting it to a format that can be used by dropdown for contract & event options
export const formatContractDataAsEventOptions = (contracts: any, chainId: number) => {
  if (!contracts) {
    return {
      contractOpts: [],
      abiOpts: [],
    };
  }
  const filteredContracts = contracts.filter((contract: any) => contract.chainId === String(chainId));

  const abiOpts = () => {
    const result: any = {};
    filteredContracts.forEach((contract: any) => {
      result[contract.id] = contract.conversionConfig.map((conversion: any) => {
        return {
          label: conversion.name,
          value: conversion,
        };
      });
    });
    return result;
  };

  return {
    contractOpts: filteredContracts.map((contract: any) => {
      return {
        label: `${contract.name} - ${contract.id}`,
        value: contract.id,
      };
    }),
    abiOpts: abiOpts(),
  };
};

/**
 * @param  {string} text
 * @description Copies text to clipboard
 */
export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export function handleEnterKeyPress<T = Element>(f: () => void) {
  return handleKeyPress<T>(f, 'Enter');
}

export function handleKeyPress<T = Element>(f: () => void, key: string) {
  return (e: KeyboardEvent<T>) => {
    if (e.key === key) {
      f();
    }
  };
}

export const axiosHeader = (bearerJwtToken: string | undefined) => ({
  Authorization: `Bearer ${bearerJwtToken}`,
  'Content-Type': 'application/json',
});

export const axiosGet = (
  url: string,
  bearerJwtToken: string | undefined,
  params?: {
    [key: string]: any;
  },
) =>
  axios.get(url, {
    headers: axiosHeader(bearerJwtToken),
    params,
  });

export function scanExplorerLink({ chainId, address, isTx = false }: { chainId?: number; address?: string; isTx?: boolean }): string {
  if (!chainId || !address) {
    return '';
  }

  const param = isTx ? 'tx' : 'address';

  if (chainId == ChainId.POLYGON) {
    return `https://polygonscan.com/${param}/${address}`;
  } else if (chainId == ChainId.OPTIMISM) {
    return `https://optimistic.etherscan.io/${param}/${address}`;
  } else if (chainId == ChainId.ARBITRUM) {
    return `https://arbiscan.io/${param}/${address}`;
  } else if (chainId == 957) {
    return `https://explorer.lyra.finance/${param}/${address}`;
  } else if (chainId == ChainId.BASE) {
    return `https://basescan.org/${param}/${address}`;
  } else if (chainId == ChainId.BASE_SEPOLIA) {
    return `https://sepolia.basescan.org/${param}/${address}`;
  } else if (chainId === 81457 /* Blast */) {
    return `https://blastscan.io/${param}/${address}`;
  } else if (chainId === 5000 /* Mantle */) {
    return `https://mantlescan.info/${param}/${address}`;
  } else if (chainId === ChainId.ARB_SEPOLIA) {
    return `https://sepolia.arbiscan.io/${param}/${address}`;
  } else if (chainId === ChainId.ETH_SEPOLIA) {
    return `https://sepolia.etherscan.io/${param}/${address}`;
  } else if (chainId === ChainId.ETHEREUM) {
    return `https://etherscan.io/${param}/${address}`;
  }
  return '';
}

export function safeExplorerLink({ chainId, address, isTx = false }: { chainId?: number; address?: string; isTx?: boolean }): string {
  if (!chainId || !address) {
    return '';
  }

  const baseUrl = 'https://app.safe.global';
  const param = isTx ? 'queue' : 'home';

  if (chainId === ChainId.ETH_SEPOLIA) {
    return `${baseUrl}/${param}?safe=sep:${address}`;
  } else if (chainId === ChainId.BASE_SEPOLIA) {
    return `${baseUrl}/${param}?safe=basesep:${address}`;
  } else if (chainId === ChainId.BASE) {
    return `${baseUrl}/${param}?safe=base:${address}`;
  } else if (chainId === ChainId.OPTIMISM) {
    return `${baseUrl}/${param}?safe=oeth:${address}`;
  } else if (chainId === ChainId.ETHEREUM) {
    return `${baseUrl}/${param}?safe=eth:${address}`;
  } else if (chainId === ChainId.ARBITRUM) {
    return `${baseUrl}/${param}?safe=arb1:${address}`;
  } else if (chainId === ChainId.POLYGON) {
    return `${baseUrl}/${param}?safe=matic:${address}`;
  } else if (chainId === ChainId.ZKSYNC) {
    return `${baseUrl}/${param}?safe=zksync:${address}`;
  }
  return '';
}

export function decodeFromUrl({ queryParam }: { queryParam: string }): { output: string } | null {
  if (!queryParam) {
    return null;
  }

  const decodedParam = decodeURIComponent(queryParam);

  try {
    return { output: decodedParam };
  } catch (err) {
    return null;
  }
}

export function transformDataForTable(chartData: ChartData) {
  const data = chartData as ChartData;

  if (!data || !data.values)
    return {
      columns: [],
      rows: [],
    };

  // Existing columns without modification
  const timestampColumns = data.values.map((entry) => ({
    name: new Date(entry.Date).toLocaleDateString('en-US', { timeZone: 'UTC' }),
  }));

  // Adjusted columns to exclude the "Total" column
  const columns: TableColumn[] = [{ name: 'Event' }, ...timestampColumns];

  const rows = data.y.map((label) => {
    const row: { [key: string]: any } = { Event: label };

    data.values.forEach((entry) => {
      const dateStr = new Date(entry.Date).toLocaleDateString('en-US', { timeZone: 'UTC' });
      row[dateStr] = entry[label];
    });

    return row;
  });

  return { columns, rows };
}

function isChartData(data: ChartData): data is ChartData {
  return (data as ChartData)?.values !== undefined;
}

export function transformLineChartToCSV(data: ChartData): string[][] {
  // Check if data is not ChartData
  if (!isChartData(data)) return [];

  // Now TypeScript knows data must be ChartData
  const headers: string[] = ['Event', ...data.values.map((entry) => new Date(entry.Date).toLocaleDateString('en-US', { timeZone: 'UTC' }))];

  const rows: string[][] = data.y.map((label) => {
    const row: string[] = [label];
    data.values.forEach((entry) => {
      row.push(String(entry[label]));
    });
    return row;
  });

  return [headers, ...rows];
}

export function transformTableToCSV(data: TableData): string[][] {
  if (!isTableData(data)) return [];

  const headers = data.columns.map((column) => column.name);
  const rows = data.rows.map((row) => data.columns.map((column) => column.formatter?.(row) || row[column.name]));

  return [headers, ...rows];
}

export function formatFunnelData(
  data: {
    name: string;
    value: number;
  }[],
): FunnelDataItem[] {
  const result = data.map((item, index) => {
    if (index === 0) {
      return {
        name: shortenText(item.name, 20),
        pv: item.value,
        uv: 0,
      };
    } else {
      const prevTopValue = data[0].value;
      const currTopValue = item.value;
      return {
        name: shortenText(item.name, 20),
        pv: currTopValue,
        uv: prevTopValue - currTopValue,
      };
    }
  });

  return result;
}

export function formatTimeseriesData(data: ChartData): any[] {
  return data.values.slice(0).map((value: any) => {
    const newValue = { ...value };

    data.y.forEach((event: string) => {
      if (typeof (newValue[event] == 'number') && isNaN(newValue[event] as number)) {
        newValue[event] = 0;
      } else {
        newValue[event] = Number(value[event]);
      }
    });

    newValue[data.x] = isDate(newValue[data.x]) ? timeSeriesFormatter(newValue[data.x], data.period) : newValue[data.x];

    return newValue;
  });
}

export function getCohortAnalysisCellColor(value: string): string {
  const v = parseFloat(value);
  if (v === 1.0) return 'bg-[#3C7B2C]';
  if (v < 1.0 && v > 0.9) {
    return 'bg-[#4A8A36]';
  }
  if (v <= 0.9 && v > 0.8) {
    return 'bg-[#579940]';
  }
  if (v <= 0.8 && v > 0.7) {
    return 'bg-[#65A84A]';
  }
  if (v <= 0.7 && v > 0.6) {
    return 'bg-[#73B754]';
  }
  if (v <= 0.6 && v > 0.5) {
    return 'bg-[#81C65E]';
  }
  if (v <= 0.5 && v > 0.4) {
    return 'bg-[#8FD568]';
  }
  if (v <= 0.4 && v > 0.3) {
    return 'bg-[#9DE372]';
  }
  if (v <= 0.3 && v > 0.2) {
    return 'bg-[#ABF17C]';
  }
  if (v <= 0.2 && v > 0.1) {
    return 'bg-[#B8FF86]';
  }
  if (v <= 0.1 && v > 0.075) {
    return 'bg-[#FFF38C]';
  }
  if (v <= 0.075 && v > 0.05) {
    return 'bg-[#FFE665]';
  }
  if (v <= 0.05 && v > 0.025) {
    return 'bg-[#FFD940]';
  }
  if (v <= 0.025 && v > 0.0) {
    return 'bg-[#FFCC1A]';
  }
  if (v === 0.0) {
    return 'bg-[#FFC000]';
  }
  return '';
}

export function transformFunnelToCSV(arr: BuildFunnelData[]): (string | number)[][] {
  if (!arr) return [];
  // Create header row
  const headerRow = ['Type', ...arr.map((item) => item.name)];

  // Create data rows
  const rows = [
    ['Unique Users', ...arr.map((item) => item.uniqueUsersMeasured)],
    ['Unique Users (Total Possible)', ...arr.map((item) => item.uniqueUsersUnbound)],
    ['Total Events', ...arr.map((item) => item.totalEventsMeasured)],
    ['Total Events (Total Possible)', ...arr.map((item) => item.totalEventsUnbound)],
  ];

  // Combine header row and data rows
  const result = [headerRow, ...rows];
  return result;
}

export const calcConversionRate = (data: (number | undefined)[]) => {
  const firstEventCount = data[0];
  const lastEventCount = data[data.length - 1];
  if (!(typeof firstEventCount === 'number') || !(typeof lastEventCount === 'number')) return '';
  if (firstEventCount === 0) return '0.00';
  const rate = Math.min((lastEventCount / firstEventCount) * 100, 100);
  return rate.toFixed(2);
};

type ConversionFormat = {
  name: string;
  [key: string]:
    | {
        total_earned: number;
        referer_total_earned: number;
        referent_total_earned: number;
        event_count: number;
      }
    | string;
};

type RewardsTableColumn = {
  name: string;
  accessorKey: string;
  formatter?: (row: any) => string;
};

export function pivotRewardsData(data?: CampaignReward[]): [ConversionFormat[], RewardsTableColumn[], RewardsTableColumn[]] {
  if (!data) return [[], [], []];

  const resultMap: { [key: string]: ConversionFormat } = {};

  const eventsSet: Set<string> = new Set();

  data.forEach((item) => {
    const channel = item.attr_attribution_channel;
    const eventName = item.event_name;

    if (!resultMap[channel]) {
      resultMap[channel] = { name: channel };
    }

    resultMap[channel][eventName] = {
      total_earned: item.referer_total_earned + item.referent_total_earned,
      referer_total_earned: ((resultMap?.[channel]?.[eventName] as any)?.referer_total_earned || 0) + item.referer_total_earned,
      referent_total_earned: ((resultMap?.[channel]?.[eventName] as any)?.referent_total_earned || 0) + item.referent_total_earned,
      event_count: ((resultMap?.[channel]?.[eventName] as any)?.event_count || 0) + item.event_count,
    };
    resultMap[channel].Total = {
      total_earned: ((resultMap[channel].Total as any)?.total_earned || 0) + (item.referer_total_earned || 0) + (item.referent_total_earned || 0),
      referer_total_earned: ((resultMap[channel].Total as any)?.referer_total_earned || 0) + (item.referer_total_earned || 0),
      referent_total_earned: ((resultMap[channel].Total as any)?.referent_total_earned || 0) + (item.referent_total_earned || 0),
      event_count: ((resultMap[channel].Total as any)?.event_count || 0) + (item.event_count || 0),
    };

    eventsSet.add(eventName);
  });

  const resultArray = Object.values(resultMap);

  const amountColumns: RewardsTableColumn[] = [];
  const countsColumns: RewardsTableColumn[] = [];
  amountColumns.push({
    name: 'Attribution Channel',
    accessorKey: 'name',
  });
  countsColumns.push({
    name: 'Attribution Channel',
    accessorKey: 'name',
  });

  amountColumns.push({
    name: 'Total (Referer | Referents)',
    accessorKey: 'Total',
    formatter: (row: any) => {
      if (!row.Total?.total_earned) return '-';
      return `$${row.Total?.total_earned?.toFixed(3)} (Referer: ${
        row.Total?.referer_total_earned ? '$' + row.Total?.referer_total_earned?.toFixed(3) : '-'
      } | Referents: ${row.Total?.referent_total_earned ? '$' + row.Total?.referent_total_earned?.toFixed(3) : '- '})`;
    },
  });

  countsColumns.push({
    name: 'Total',
    accessorKey: 'Total',
    formatter: (row: any) => {
      if (!row.Total?.event_count) return '-';
      return row.Total?.event_count;
    },
  });

  eventsSet.forEach((event) => {
    amountColumns.push({
      name: `${event}`,
      accessorKey: event,
      formatter: (row: any) => {
        if (!row[event]?.total_earned) return '-';
        return `$${row[event]?.total_earned?.toFixed(3)} (Referer: ${
          row[event]?.referer_total_earned ? '$' + row[event]?.referer_total_earned?.toFixed(3) : '-'
        } | Referents: ${row[event]?.referent_total_earned ? '$' + row[event]?.referent_total_earned?.toFixed(3) : '- '})`;
      },
    });

    countsColumns.push({
      name: event,
      accessorKey: event,
      formatter: (row: any) => {
        if (!row[event]?.event_count) return '-';
        return row[event]?.event_count;
      },
    });
  });

  return [resultArray, amountColumns, countsColumns];
}

export const prettyPayout = (payoutType?: PayoutType | string) => {
  switch (payoutType) {
    case PayoutType.CPA:
      return 'CPA';
    case PayoutType.CPV:
      return 'CPV';
    case PayoutType.CPA_ONE_TIME:
      return 'CPA';
    case PayoutType.CPV_ONE_TIME:
      return 'CPV';
    case PayoutType.CPA_RECURRING:
      return 'CPA';
    case PayoutType.CPV_RECURRING:
      return 'CPV';
    default:
      return payoutType;
  }
};

export const prettyCadence = (cadence?: string) => {
  switch (cadence) {
    case 'one_time':
      return 'First Action';
    case 'recurring':
      return 'Recurring';
    default:
      return cadence;
  }
};

export function groupEventOptions({
  eventOptions,
}: {
  eventOptions: EventOption[];
}): { label: string; options: { label: string; value: string }[] }[] {
  const options = [
    { label: 'Default Events', options: [] },
    { label: 'Onchain Events', options: [] },
    { label: 'Custom Events', options: [] },
    { label: 'Actions', options: [] },
    { label: 'Third Party Events', options: [] },
  ] as { label: string; options: { label: string; value: string }[] }[];

  eventOptions.forEach((option, idx) => {
    switch (option.eventType as any) {
      case EventLogName.contract_conversion:
        options[1].options.push({ label: option.label, value: idx.toString() });
        break;
      case EventLogName.custom:
        options[2].options.push({ label: option.label, value: idx.toString() });
        break;
      case EventLogName.third_party:
        options[4].options.push({ label: option.label, value: idx.toString() });
        break;
      case EventLogName.action:
        options[3].options.push({ label: option.label, value: idx.toString() });
        break;
      default:
        options[0].options.push({ label: option.label, value: idx.toString() });
        break;
    }
  });

  return options;
}

export function groupDimOptions({ dimOptions }: { dimOptions: DimensionOption[] }): { label: string; options: { label: string; value: string }[] }[] {
  const options = [] as { label: string; options: { label: string; value: string }[] }[];

  dimOptions.forEach((option) => {
    const category = option.category ?? 'Default';
    let optionIdx = options.findIndex((o) => o.label === category);
    if (optionIdx === -1) {
      options.push({ label: category, options: [] });
      optionIdx = options.length - 1;
    }
    options[optionIdx].options.push({ label: option.label, value: option.id });
  });

  return options;
}

export function formatReward(type: string | undefined, reward: number | undefined): string {
  if (type == PayoutType.CPA) {
    return `${reward} wei per conversion`;
  } else if (type == PayoutType.CPV) {
    return `${reward}%`;
  }
  return reward?.toString() || '';
}

export const defaultEvent = {
  id: uuidv4(),
  label: '',
  eventName: '',
  measure: { id: '', aggType: '', label: '' },
};
